.circleTip {
  box-shadow: none;
  border: 0px solid blue;
  padding: 1px;
  font-size: x-small;

  color: white;
  font-weight: bold;

  border-radius: 10px;
  background-color: #ff2c3a;
  padding: 0px 5px;
}
